import React, { useState } from 'react'
import {
  //   Magnifier,
  //   GlassMagnifier,
  SideBySideMagnifier,
  //   PictureInPictureMagnifier,
  //   MOUSE_ACTIVATION,
  //   TOUCH_ACTIVATION,
} from 'react-image-magnifiers'
import '../../style/productpage.css'
import Product from '../scenes/product'
export default function ProductPage() {
  const [count, setcount] = useState(1)
  function changecount(e) {
    if (count < 1) {
      setcount(1)
    } else if (count === 0) {
      setcount(1)
    } else {
      if (e === '+') {
        setcount(count + 1)
      } else {
        setcount(count - 1)
      }
    }
  }
  return (
    <>
      <div className="productpage page">
        <div className="productinfoinpage infoinproduct">
          <div className="productrate">
            <div className="rate">
              <span class="material-symbols-outlined">star</span>
              <span class="material-symbols-outlined">star</span>
              <span class="material-symbols-outlined">star</span>
              <span class="material-symbols-outlined">star</span>
            </div>
            <div className="prorateinfo">240 Rating</div>
          </div>
          <div className="productname">
            Aconagua 2 Down Vest Aconagua 2 Down Vest Aconagua 2 Down Vest
          </div>
          <div className="productprandname">THE NORTH FACE</div>
          <div className="productprice">EGP 2,911.94</div>
          <div className="productsize">
            <select>
              <option value="Small">Small</option>
              <option value="large">large</option>
              <option value="xlarge">x large</option>
            </select>
          </div>
          <div className="productcolor">
            <select>
              <option value="red">
                red <span className="colorshoice red"></span>
              </option>
              <option value="white">
                white <span className="colorshoice white"></span>
              </option>
              <option value="black">
                black <span className="colorshoice black"></span>
              </option>
            </select>
          </div>
          <div className="productcolorsh">
            <span className="colorshoice white"></span>
            <span className="colorshoice blow selectedcolor"></span>
            <span className="colorshoice black"></span>
            <span className="colorshoice red "></span>
          </div>
          <div className="productcount countproduct">
            <div
              className="plus"
              onClick={() => {
                changecount('+')
              }}
            >
              +
            </div>
            <div className="input">{count}</div>
            <div
              className="minus"
              onClick={() => {
                changecount('-')
              }}
            >
              -
            </div>
          </div>
          <div className="actioninproduct">
            <div className="addtocartbutton">
              <span className="btn addtocart">
                Add To Cart <span class="material-symbols-outlined">add</span>
              </span>
              <span className="btn addtowashlist">
                <span class="material-symbols-outlined">favorite</span>
                Add To washlist
              </span>
            </div>
          </div>
        </div>
        <div className="productinfoinpage">
          <div className="productpageimage">
            <SideBySideMagnifier
              imageSrc={require('../../data/in/Screenshot2022-10-24033410.jpg')}
              imageAlt="Example"
              alwaysInPlace={'false'}
              // overlayOpacity={0.5}
              // switchSides={'false'}
              // zoomPosition="right"
              // inPlaceMinBreakpoint={641}
              // fillAvailableSpace={true}
              // fillAlignTop={'false'}
              // fillGapTop={10}
              // fillGapRight={10}
              // fillGapBottom={10}
              // fillGapLeft={10}
              zoomContainerBorder="1px solid #ccc"
              zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
              // largeImageSrc="https://adamrisberg.github.io/react-image-magnifiers/4700d4cb26b14563be996aa5f0c53ca2.jpg" // Optional
              // mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK} // Optional
              // touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP} // Optional
            />
            <SideBySideMagnifier
              imageSrc={require('../../data/in/Screenshot2022-10-24025408.jpg')}
              imageAlt="Example"
              alwaysInPlace={'false'}
              zoomContainerBorder="1px solid #ccc"
              zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
            />
          </div>
        </div>
      </div>
      <div className="page ">
        <div className="title">DETAILS & CARE</div>
        <div className="product-description">
          <div className="aboutbrand">
            <div className="brandtitle">The North Face</div>
            <div className="brandinfo">
              The North Face is obsessively devoted to providing the best gear
              for athletes, modern-day explorers and urban adventurers alike.
              Proprietary technology and a deep understanding of truly great
              performance gear make The North Face the logical choice for those
              who need to stay warm, dry and stylish.
            </div>
          </div>
          <div className="aboutbrand">
            <div className="brandinfo">
              Down insulation and quilted construction give this vest unbeatable
              warmth for chilly hikes when the sun goes down. 27" length (size
              Medium) Front zip closure Side pockets with zip closure; chest
              pocket with zip closure Lined, with down fill 100% polyester
              Machine wash, tumble dry Imported Men's Clothing Item #5999604
            </div>
          </div>
        </div>
      </div>
      <div className="seemoreproduct page">
        <div className="title">Recommended For You</div>
        <div className="firstcollection">
          <Product
            title="INES COVER UP"
            price="200"
            imgurl="Screenshot2022-10-20183350.jpg"
          />
          <Product
            title="ESHA TUNIC IN OFF-WHITE"
            price="260"
            imgurl="Screenshot2022-10-20182214.jpg"
          />
          <Product
            title="MATA BEACH COAT"
            price="210"
            imgurl="Screenshot2022-10-20182040.jpg"
          />
          <Product
            title="ZURI COVER UP"
            price="180"
            imgurl="Screenshot2022-10-20181907.jpg"
          />
        </div>
      </div>
      <div className="seemoreproduct page">
        <div className="title">From The North Face</div>
        <div className="firstcollection">
          <Product
            title="INES COVER UP"
            price="200"
            imgurl="Screenshot2022-10-20183350.jpg"
          />
          <Product
            title="ESHA TUNIC IN OFF-WHITE"
            price="260"
            imgurl="Screenshot2022-10-20182214.jpg"
          />
          <Product
            title="MATA BEACH COAT"
            price="210"
            imgurl="Screenshot2022-10-20182040.jpg"
          />
          <Product
            title="ZURI COVER UP"
            price="180"
            imgurl="Screenshot2022-10-20181907.jpg"
          />
        </div>
      </div>

      <div className="page">
        <div className="title">REVIEWS</div>
        <div className="reviewsection">
          <span class="material-symbols-outlined reviewsout">reviews</span>
          <div className="rate">
            <span class="material-symbols-outlined">star</span>
            <span class="material-symbols-outlined">star</span>
            <span class="material-symbols-outlined">star</span>
            <span class="material-symbols-outlined">star</span>
          </div>
          <div className="prorateinfo">240 Rating</div>
        </div>
      </div>
      <div className="page">
        <div className="title">HAVE QUESTIONS ?</div>
        <div className="helpadvise">
          <span class="material-symbols-outlined">live_help</span>
          <div>
            Connect with us to get free style advice, product recommendations or
            customer service help.
          </div>
        </div>
        <div className="btn1">Chat customer service</div>
      </div>
      {/* <div className="page">
        <div className="title">STYLE IDEAS FOR THIS ITEM</div>
      </div> */}
      <div className="page">
        <div className="title">Recently viewed</div>
        <div className="firstcollection">
          <Product
            title="INES COVER UP"
            price="200"
            imgurl="Screenshot2022-10-20183350.jpg"
          />
        </div>
      </div>
    </>
  )
}
