import React, { useEffect, useState, useRef } from 'react'
import '../../style/inputfiled.css'
export default function Inputfiled(props) {
  const [getvalue, setvalue] = useState('')
  if (props.value) {
    setvalue(props.value)
  }
  useEffect(() => {
    const inputplaceholder = document.getElementById('placeholder' + props.name)
    if (getvalue.length > 0) {
      inputplaceholder.style.cssText = 'top:10px !important'
    } else {
      inputplaceholder.style.cssText = 'top:30px !important'
    }
  }, [getvalue])

  const showpassword = () => {
    var inputid = document.getElementById(props.name)
    var showpasswordclass = document.getElementById('show' + props.name)
    if (inputid.type === 'password') {
      inputid.type = 'text'
      showpasswordclass.innerHTML = 'hide'
    } else {
      inputid.type = 'password'
      showpasswordclass.innerHTML = 'show'
    }
  }

  return (
    <div className="inputfild">
      <div className="inputplaceholder" id={'placeholder' + props.name}>
        {props.placeholder}
      </div>
      <input
        type={props.type}
        //   placeholder="Enter Your Name"
        name={props.name}
        id={props.name}
        value={getvalue}
        onChange={(e) => {
          setvalue(e.target.value)
        }}
        ref={useRef(props.name)}
      />
      {props.mood === 'password' ? (
        <>
          <div
            className="showpassword"
            id={'show' + props.name}
            onClick={showpassword}
          >
            show
          </div>
        </>
      ) : null}
    </div>
  )
}
