import React, { useState, useEffect, memo } from 'react'
import '../../style/products.css'
import { Link } from 'react-router-dom'
function Product(props) {
  //   const [product, setproduct] = useState(0)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      //   setproduct(1)
      setLoaded(true)
    }, 1000)
  }, [])
  return (
    <>
      {localStorage.getItem('Lang') === 'en' ? (
        <>
          {loaded ? (
            <Link to="/pro/dff/dfdfd">
              <div className="product">
                <div className="productimg">
                  <img
                    src={require(`../../data/in/${props.imgurl}`)}
                    onLoad={() => setLoaded(true)}
                    alt="productimage"
                  />
                </div>
                <div className="producttitle">{props.title}</div>
                <div className="productprice">{props.price} $</div>
              </div>
            </Link>
          ) : (
            <>
              <div className="product linear-background">
                <div className="productimg"></div>
                <div className="producttitle"></div>
                <div className="productprice"></div>
                <div className="productprice2"></div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {loaded ? (
            <>
              <div className="product ar">
                <div className="productimg">
                  <img
                    src={require(`../../data/in/${props.imgurl}`)}
                    onLoad={() => setLoaded(true)}
                    alt="productimage"
                  />
                </div>
                <div className="producttitle">{props.title}</div>
                <div className="productprice">{props.price} $</div>
              </div>
            </>
          ) : (
            <>
              <div className="product linear-background ar">
                <div className="productimg ar"></div>
                <div className="producttitle ar"></div>
                <div className="productprice ar"></div>
                <div className="productprice2 ar"></div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
export default memo(Product)
