import React from 'react'
import Product from '../scenes/product'
import Cover from '../scenes/cover'

export default function Home() {
  return (
    <>
      <div className="home">
        <Cover
          coverimage="257e65f5acf14062b01fef76fdda205fe0eeacd4_begoldish-hp-slider-banner.jpg"
          coverimage2="cover.jpeg"
        />
        <div className="firstcollection">
          <Product
            title="INES COVER UP"
            price="200"
            imgurl="Screenshot2022-10-20183350.jpg"
          />
          <Product
            title="ESHA TUNIC IN OFF-WHITE"
            price="260"
            imgurl="Screenshot2022-10-20182214.jpg"
          />
          <Product
            title="MATA BEACH COAT"
            price="210"
            imgurl="Screenshot2022-10-20182040.jpg"
          />
          <Product
            title="ZURI COVER UP"
            price="180"
            imgurl="Screenshot2022-10-20181907.jpg"
          />
        </div>
      </div>
    </>
  )
}
